import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Gallery from '../components/Gallery'
import Helmet from '../components/Helmet'

export const IndexPageTemplate = ({ portfolio, hasInfo }) => <Gallery {...portfolio} hasInfo={hasInfo} />

IndexPageTemplate.propTypes = {
  portfolio: PropTypes.shape({
    description: PropTypes.string,
    gallery: PropTypes.array,
    title: PropTypes.string
  }),
  hasInfo: PropTypes.bool
}

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data
  const { index = [] } = page.frontmatter
  const { frontmatter, html } = index.length > 0 && index[0].project
  const hasInfo = html || frontmatter.description
  return (
    <React.Fragment>
      <Helmet />
      <IndexPageTemplate
        portfolio={frontmatter}
        hasInfo={hasInfo}
      />
    </React.Fragment>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        index {
          thumbnail {
            url
            aspectRatio
            name
          }
          project {
            fields {
              slug
            }
            frontmatter {
              title
              subtitle
              slug
              description
              gallery {
                images {
                  url
                  name
                  aspectRatio
                  isImage
                }
                caption
              }
            }
            html
          }
        }
      }
    }
  }
`
